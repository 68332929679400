/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import Types from 'prop-types';
import { Helmet } from 'react-helmet';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import theme from '../../src/theme';

export default function Layout({ children }) {
  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
        />
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
      </Helmet>

      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container maxWidth="md">{children}</Container>
      </ThemeProvider>
    </>
  );
}

Layout.propTypes = {
  children: Types.node.isRequired,
};
