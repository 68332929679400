import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

const theme = responsiveFontSizes(
  createMuiTheme({
    palette: {
      type: 'dark',
      primary: {
        main: '#ffa7c4',
      },
      secondary: {
        // needed for Radio buttons for now
        main: '#ffa7c4',
      },
      background: {
        default: '#282c35',
      },
    },
  }),
);

export default theme;
