/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import Types from 'prop-types';

import Layout from './Layout';

export const wrapRootElement = ({ element }) => {
  return <Layout>{element}</Layout>;
};

wrapRootElement.propTypes = {
  element: Types.node.isRequired,
};
